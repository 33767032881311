<template>
  <div>
    <a-modal
      :visible="visible"
      title="计划排产"
      :width="1200"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      okText="排产"
      @cancel="cancel"
      @ok="confirm"
    >
      <a-form-model ref="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="生产工单号">
              <a-input :value="form.number" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="生产数量">
              <a-input :value="form.total_quantity" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="计划开始时间">
              <a-input :value="form.planned_start_time" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="计划完成时间">
              <a-input :value="form.planned_end_time" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="物料编号">
              <a-input :value="form.goods_number" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="物料名称">
              <a-input :value="form.goods_name" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="物料规格">
              <a-input :value="form.goods_spec" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="备注">
              <a-input :value="form.remark" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <div style="overflow-x: auto">
              <a-steps :current="-1" type="navigation" size="small" :style="{ padding: '12px' }">
                <a-step v-for="item in productionProcessItems" status="wait" :key="item.id" style="margin-left: 12px">
                  <template slot="title">
                    <div>{{ item.process_item.name }}</div>
                  </template>
                </a-step>
              </a-steps>
            </div>
          </a-col>

          <a-col :span="24">
            <a-table
              rowKey="id"
              size="small"
              :columns="columns"
              :data-source="productionTaskItems"
              :pagination="false"
              :scroll="{ x: 1400 }"
            >
              <template slot="workstation" slot-scope="value, item">
                <a-select v-model="item.workstation" :allowClear="true" size="small" style="width: 120px">
                  <a-select-option
                    v-for="workstationItem in workstationItems"
                    :key="workstationItem.id"
                    :value="workstationItem.id"
                  >
                    {{ workstationItem.name }}
                  </a-select-option>
                </a-select>
              </template>

              <template slot="operator_set" slot-scope="value, item">
                <a-select v-model="item.operator_set" mode="multiple" size="small" :allowClear="true" style="width: 120px">
                  <a-select-option v-for="item in userItems" :key="item.id" :value="item.id" :item="item">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </template>
              <template slot="total_quantity" slot-scope="value, item">
                <a-input-number v-model="item.total_quantity" size="small" />
              </template>

              <template slot="start_time" slot-scope="value, item">
                <a-date-picker
                  v-model="item.start_time"
                  placeholder="请选择时间"
                  show-time
                  size="small"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                />
              </template>
              <template slot="complete_time" slot-scope="value, item">
                <a-date-picker
                  v-model="item.complete_time"
                  placeholder="请选择时间"
                  show-time
                  size="small"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                />
              </template>
              <template slot="action" slot-scope="value, item">
                <a-button type="danger" size="small" @click="removeProductionTask(index)">移除</a-button>
              </template>
              <template slot="footer">
                <a-button-group style="width: 100%">
                  <a-button type="dashed" block @click="addProductionTaskModalVisible = true">添加生产任务</a-button>
                </a-button-group>
              </template>
            </a-table>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <a-modal v-model="addProductionTaskModalVisible" title="添加生产任务" @ok="addProductionTask">
      <a-form-item label="生产工序" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
        <a-select v-model="addProductionProcess" style="width: 100%">
          <a-select-option
            v-for="productionProcessItem in productionProcessItems"
            :key="productionProcessItem.id"
            :value="productionProcessItem.id"
          >
            {{ productionProcessItem.process_item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-modal>
  </div>
</template>

<script>
import { productionOrderProductionRoute, productionOrderProductionTask, productionOrderScheduling } from "@/api/production";
import { workstationOption, userOption } from "@/api/option";
import moment from "moment";

export default {
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      currentProcess: 0,
      productionProcessItems: [],
      productionTaskItems: [],
      workstationItems: [],
      userItems: [],

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "任务名称",
          dataIndex: "production_process",
          customRender: (value, item) => item.process_item.name,
        },
        {
          title: "工作站",
          dataIndex: "workstation",
          scopedSlots: { customRender: "workstation" },
        },
        {
          title: "操作员",
          dataIndex: "operator_set",
          scopedSlots: { customRender: "operator_set" },
        },
        {
          title: "排产数量",
          dataIndex: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
        },
        {
          title: "已生产数量",
          dataIndex: "production_quantity",
        },
        {
          title: "开始生产时间",
          dataIndex: "start_time",
          width: 240,
          scopedSlots: { customRender: "start_time" },
        },
        {
          title: "预计完成时间",
          dataIndex: "complete_time",
          width: 240,
          scopedSlots: { customRender: "complete_time" },
        },
        {
          title: "状态",
          dataIndex: "is_confirm",
          width: 80,
          customRender: (value) => (value ? "已确认" : "待确认"),
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],

      addProductionTaskModalVisible: false,
      addProductionProcess: null,
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    confirm() {
      this.loading = true;
      productionOrderScheduling({ id: this.form.id, production_task_items: this.productionTaskItems })
        .then((data) => {
          this.$message.success("排产完成");
          this.cancel();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
    addProductionTask() {
      if (!this.addProductionProcess) {
        this.$message.warning("请选择生产工序");
        return;
      }

      const index = this.productionProcessItems.findIndex((item) => item.id === this.addProductionProcess);
      const productionProcessItem = this.productionProcessItems[index];
      this.productionTaskItems.push({
        id: -Date.now(),
        production_process: productionProcessItem.id,
        process: productionProcessItem.process_item.id,
        process_item: productionProcessItem.process_item,
        workstation: undefined,
        workstation_item: {},
        total_quantity: this.form.total_quantity,
        production_quantity: 0,
        start_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        complete_time: undefined,
        is_confirm: false,
      });
      this.addProductionTaskModalVisible = false;
      this.addProductionProcess = null;
    },
    removeProductionTask(index) {
      this.productionTaskItems.splice(index, 1);
    },
  },
  watch: {
    visible(value) {
      if (value) {
        productionOrderProductionRoute({ id: this.form.id }).then((data) => {
          this.productionProcessItems = data.production_process_items;
          this.productionProcessItems.sort((a, b) => a.step_number - b.step_number);
        });

        productionOrderProductionTask({ id: this.form.id }).then((data) => {
          this.productionTaskItems = data;
        });

        workstationOption().then((data) => {
          this.workstationItems = data.results;
        });

        userOption().then((data) => {
          this.userItems = data.results;
        });
      }
    },
  },
};
</script>

<style scoped></style>
