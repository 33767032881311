<template>
  <div>
    <a-modal :visible="visible" title="物料选择" :width="1000" :footer="null" @cancel="onCloseModel">
      <a-row gutter="16">
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="物料名称, 编码, 条码" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.category" placeholder="分类" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in categoryItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
          </a-select>
        </a-col>

        <a-space>
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-space>

        <div style="float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="goodsCreateModalVisible = true">新增物料</a-button>
        </div>
      </a-row>

      <div style="margin-top: 12px">
        <a-table
          size="small"
          :data-source="items"
          rowKey="id"
          :columns="columns"
          :loading="loading"
          :pagination="pagination"
          @change="onChangeTable"
        >
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button size="small" @click="select(item)">选择</a-button>
              <a-button type="primary" @click="copy(item)" size="small">复制</a-button>
            </a-button-group>
          </div>
        </a-table>
      </div>
    </a-modal>

    <GoodsCreateModal v-model="goodsCreateModalVisible" :item="copyItem" @create="list" @cancel="copyItem = null" />
  </div>
</template>

<script>
import { salesInventoryOption, goodsClassificationOption } from "@/api/option";
import { columns } from "./columns";

export default {
  components: {
    GoodsCreateModal: () => import("@/components/GoodsCreateModal"),
  },
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      columns,
      pagination: {},
      searchForm: { search: "" },
      loading: false,
      items: [],

      goodsCreateModalVisible: false,
      copyItem: null,
      categoryItems: [],
    };
  },
  methods: {
    onCloseModel() {
      this.$emit("cancel", false);
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      this.loading = true;
      salesInventoryOption(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    select(item) {
      this.$emit("select", item);
      this.onCloseModel();
    },
    copy(item) {
      this.copyItem = item;
      this.goodsCreateModalVisible = true;
    },
    resetModel() {
      this.pagination = { current: 1, total: 0, pageSize: 10, showTotal: (total) => `共 ${total} 条` };
      this.searchForm = { page: 1 };
      this.items = [];
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.resetModel();
        this.list();

        goodsClassificationOption({ page_size: 999999 }).then((data) => {
          this.categoryItems = data.results;
        });
      }
    },
  },
};
</script>
