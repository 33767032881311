<template>
  <div>
    <a-card title="库存报表">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="物料编号/名称" allowClear @search="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select
            v-model="searchForm.inventory_status"
            placeholder="库存状态"
            allowClear
            style="width: 100%"
            @change="search"
          >
            <a-select-option value="normal">正常</a-select-option>
            <a-select-option value="abnormal">异常</a-select-option>
          </a-select>
        </a-col>

        <a-col :span="4" style="width: 90px">
          <a-button icon="download" @click="exportExcel">导出</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          rowKey="id"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <template slot="allocated_quantity" slot-scope="value, item">
            <a @click="openProductionModal(item)">{{ value }}</a>
          </template>
        </a-table>
      </a-row>
    </a-card>

    <ProductionModal v-model="productionModalVisible" :item="targetItem" />
  </div>
</template>

<script>
import { goodsInventoryReportList } from "@/api/report";
import { goodsInventoryExport } from "@/api/export";
import { exportExcel } from "@/utils/excel";
import NP from "number-precision";

export default {
  components: {
    ProductionModal: () => import("./ProductionModal"),
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "物料名称",
          dataIndex: "name",
        },
        {
          title: "物料编号",
          dataIndex: "number",
        },
        {
          title: "物料条码",
          dataIndex: "barcode",
        },
        {
          title: "库存数量",
          dataIndex: "inventory_quantity",
        },
        {
          title: "已分配生产库存",
          dataIndex: "allocated_quantity",
          scopedSlots: { customRender: "allocated_quantity" },
        },
        {
          title: "可用库存",
          dataIndex: "available_quantity",
          customRender: (_, item) => NP.minus(item.inventory_quantity, item.allocated_quantity),
        },
        {
          title: "物料规格",
          dataIndex: "spec",
        },
        {
          title: "物料分类",
          dataIndex: "category_name",
        },
        {
          title: "物料单位",
          dataIndex: "unit_name",
        },
        {
          title: "库存状态",
          dataIndex: "inventory_status",
          customRender: (_, item) => {
            if (item.enable_inventory_warning) {
              if (item.inventory_quantity <= item.inventory_lower) {
                return "不足";
              } else if (item.inventory_quantity >= item.inventory_upper) {
                return "超出";
              }
            }

            return "正常";
          },
        },
      ],
      searchForm: { page: 1, page_size: 10 },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      items: [],
      visible: false,
      targetItem: {},
      form: {},

      productionModalVisible: false,
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.searchForm.inventory_status = this.$route.query.inventory_status;
      this.list();
    },
    list() {
      this.loading = true;
      goodsInventoryReportList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    exportExcel() {
      goodsInventoryExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, "库存报表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    openProductionModal(item) {
      this.targetItem = item;
      this.productionModalVisible = true;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
