<template>
  <div>
    <a-card title="待审批任务">
      <a-row gutter="16">
        <a-col :span="24" style="width: 200px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="审批单号" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 200px; margin-bottom: 12px">
          <a-select v-model="searchForm.type" placeholder="审批类型" allowClear style="width: 100%" @change="search">
            <a-select-option value="purchase_requisition">采购申请</a-select-option>
            <a-select-option value="purchase_return">采购退货</a-select-option>
            <a-select-option value="sales_quotation">销售报价</a-select-option>
            <a-select-option value="sales_update">销售合同更新</a-select-option>
            <a-select-option value="inspection">检验报告</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <template slot="order_number" slot-scope="value, item">
            <a
              v-if="item.approval_order_item.type === 'purchase_requisition'"
              @click="jumpPurchaseRequisitionOrderDetail(item)"
            >
              {{ item.approval_order_item.purchase_requisition_order_item.number }}
            </a>
            <a v-if="item.approval_order_item.type === 'purchase_return'" @click="jumpPurchaseReturnOrderDetail(item)">
              {{ item.approval_order_item.purchase_return_order_item.number }}
            </a>
            <a v-if="item.approval_order_item.type === 'sales_quotation'" @click="jumpSalesQuotationOrderDetail(item)">
              {{ item.approval_order_item.sales_quotation_order_item.number }}
            </a>
            <a v-if="item.approval_order_item.type === 'sales_update'" @click="jumpSalesOrderDetail(item)">
              {{ item.approval_order_item.sales_order_item.number }}
            </a>
            <a v-if="item.approval_order_item.type === 'inspection'" @click="jumpInspectionReportDetail(item)">
              {{ item.approval_order_item.inspection_report_item.number }}
            </a>
          </template>
          <template slot="action" slot-scope="value, item">
            <TableAction :item="item" @update="list" />
          </template>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { approvalTaskList } from "@/api/approval";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "审批单号",
          dataIndex: "order_number",
          width: 160,
          scopedSlots: { customRender: "order_number" },
        },
        {
          title: "审批类型",
          dataIndex: "type",
          customRender: (_, item) => item.approval_order_item.type_display,
        },
        {
          title: "申请人",
          dataIndex: "creator",
          customRender: (_, item) => item.approval_order_item.creator_item.name,
        },
        {
          title: "申请时间",
          dataIndex: "create_time",
          customRender: (_, item) => item.approval_order_item.create_time,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
      searchForm: { search: "", page: 1, page_size: 10 },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      items: [],
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      approvalTaskList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    jumpPurchaseRequisitionOrderDetail(item) {
      this.$router.push({
        path: "/purchasing/purchase_requisition_order_detail",
        query: { id: item.approval_order_item.purchase_requisition_order },
      });
    },
    jumpPurchaseReturnOrderDetail(item) {
      this.$router.push({
        path: "/purchasing/return_record_detail",
        query: { id: item.approval_order_item.purchase_return_order },
      });
    },
    jumpSalesQuotationOrderDetail(item) {
      this.$router.push({
        path: "/sale/sales_quotation_order_detail",
        query: { id: item.approval_order_item.sales_quotation_order },
      });
    },
    jumpSalesOrderDetail(item) {
      this.$router.push({ path: "/sale/sales_order_detail", query: { id: item.approval_order_item.sales_order } });
    },
    jumpInspectionReportDetail(item) {
      this.$router.push({
        path: "/inspection/inspection_report_detail",
        query: { id: item.approval_order_item.inspection_report },
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
