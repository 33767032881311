<template>
  <div>
    <a-card title="成品检验报告">
      <a-row gutter="16">
        <a-col :span="24" style="width: 240px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="成品检验报告单号/关联单号" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 200px; margin-bottom: 12px">
          <a-select v-model="searchForm.inspector" placeholder="检验人" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
          </a-select>
        </a-col>

        <a-col :span="24" style="margin-bottom: 12px; width: 120px">
          <a-button-group>
            <a-button @click="printInspectionReport">打印成品检验报告</a-button>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="handleAdd">新增成品检验报告</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="dataItems"
          :loading="loading"
          :pagination="pagination"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="tableChange"
        >
          <template slot="number" slot-scope="value, item">
            <a @click="jumpInspectionReportDetail(item)">
              {{ item.number }}
            </a>
          </template>

          <template slot="order_number" slot-scope="value, item">
            <a v-if="item.stock_in_order_item.type === 'purchase'" @click="jumpPurchaseOrderDetail(item)">
              {{ item.stock_in_order_item.purchase_order_item.number }}
            </a>
            <a v-if="item.stock_in_order_item.type === 'production_stock_in'" @click="jumpProductionOrderDetail(item)">
              {{ item.stock_in_order_item.production_order_item.number }}
            </a>
          </template>

          <template slot="action" slot-scope="value, item">
            <TableAction :item="item" @update="handleUpdate" @delete="handleDelete" />
          </template>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { inspectionReportList } from "@/api/inspection";
import { userOption } from "@/api/option";
import lodopPrintInspectionReport from "./printInspectionReport";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "检验报告单号",
          dataIndex: "number",
          width: 160,
          scopedSlots: { customRender: "number" },
        },
        {
          title: "关联单号",
          dataIndex: "order_number",
          width: 160,
          scopedSlots: { customRender: "order_number" },
        },
        {
          title: "物料名称",
          dataIndex: "product_name",
          customRender: (_, item) => item.inspection_product_items.map((item) => item.goods_item.name).join(", "),
        },
        {
          title: "类型",
          dataIndex: "type",
          customRender: (_, item) => item.stock_in_order_item.type_display,
        },
        {
          title: "检验日期",
          dataIndex: "inspection_date",
        },
        {
          title: "检验人",
          dataIndex: "inspector",
          customRender: (_, item) => item.inspector_item.name,
        },
        {
          title: "检验结果",
          dataIndex: "inspection_result_display",
        },
        // {
        //   title: "检验报告",
        //   dataIndex: "inspection_content",
        // },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "状态",
          dataIndex: "status_display",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ],
      searchForm: { search: "", page: 1, page_size: 10, type: "production_stock_in" },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      dataItems: [],
      userItems: [],

      selectedRowKeys: [],
      selectedRowItems: [],
    };
  },
  computed: {},
  methods: {
    initData() {
      this.list();

      userOption({ page_size: 99999 }).then((resp) => {
        this.userItems = resp.results;
      });
    },
    list() {
      this.loading = true;
      inspectionReportList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleUpdate(item) {
      this.dataItems = this.$functions.replaceItem(this.dataItems, item);
    },
    handleDelete(item) {
      this.dataItems = this.$functions.removeItem(this.dataItems, item);
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    jumpPurchaseOrderDetail(item) {
      this.$router.push({
        path: "/purchasing/purchase_record_detail",
        query: { id: item.stock_in_order_item.purchase_order_item.id },
      });
    },
    jumpProductionOrderDetail(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.stock_in_order_item.production_order_item.id } });
    },
    jumpInspectionReportDetail(item) {
      this.$router.push({ path: "/inspection/production_inspection_report_detail", query: { id: item.id } });
    },
    handleAdd() {
      this.$router.push({ path: "/inspection/production_inspection_report_create" });
    },
    onSelectChange(selectedRowKeys, selectedRowItems) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowItems = selectedRowItems;
    },
    printInspectionReport() {
      if (this.selectedRowItems.length === 0) {
        this.$message.warning("请选择要打印的检验报告");
        return;
      }

      lodopPrintInspectionReport(this.selectedRowItems);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
