<template>
  <div>
    <a-card title="库存明细">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="物料编号/名称" allowClear @search="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
          </a-select>
        </a-col>
        <!-- <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.has_stock" placeholder="库存状态" allowClear style="width: 100%" @change="search">
            <a-select-option :value="true">有库存</a-select-option>
            <a-select-option :value="false">无库存</a-select-option>
          </a-select>
        </a-col> -->

        <a-col :span="4" style="width: 90px">
          <a-button icon="download" @click="exportExcel">导出</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { warehousesOption } from "@/api/option";
import { batchsReportList } from "@/api/report";
import { exportExcel } from "@/utils/excel";
import { batchExport } from "@/api/export";

export default {
  name: "Warehouse",
  components: {},
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "物料条码",
          dataIndex: "goods_barcode",
        },
        {
          title: "总数量",
          dataIndex: "remain_quantity",
        },
        {
          title: "物料规格",
          dataIndex: "goods_spec",
        },
        {
          title: "物料分类",
          dataIndex: "category_name",
        },
        {
          title: "批次编号",
          dataIndex: "number",
        },
        {
          title: "仓库",
          dataIndex: "warehouse_name",
        },
        {
          title: "物料单位",
          dataIndex: "unit_name",
        },
      ],
      searchForm: { search: "", page: 1, page_size: 10 },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      items: [],
      warehouseItems: [],
    };
  },
  computed: {},
  methods: {
    initialize() {
      warehousesOption({ page_size: 99999 }).then((resp) => {
        this.warehouseItems = resp.results;
      });

      this.list();
    },
    list() {
      this.loading = true;
      batchsReportList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    exportExcel() {
      batchExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, "库存明细");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
