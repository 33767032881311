<template>
  <div>
    <a-card title="操作日志">
      <a-row gutter="16">
        <a-col :span="24" style="width: 256px">
          <a-range-picker @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.creator" placeholder="操作人员" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in userItems" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.module" placeholder="系统模块" allowClear style="width: 100%" @change="search">
            <a-select-option value="approval">工作流</a-select-option>
            <a-select-option value="client">客户管理</a-select-option>
            <a-select-option value="supplier">供应商管理</a-select-option>
            <a-select-option value="warehouse">仓库管理</a-select-option>
            <a-select-option value="account">结算账户</a-select-option>
            <a-select-option value="charge_category">收支项目</a-select-option>
            <a-select-option value="goods_category">物料分类</a-select-option>
            <a-select-option value="unit">物料单位</a-select-option>
            <a-select-option value="goods">物料信息</a-select-option>
            <a-select-option value="bom">BOM管理</a-select-option>
            <a-select-option value="purchase">采购开单</a-select-option>
            <a-select-option value="purchase_return">采购退货</a-select-option>
            <a-select-option value="sales">销售开单</a-select-option>
            <a-select-option value="sales_return">销售退货</a-select-option>
            <a-select-option value="production_order">生产工单</a-select-option>
            <a-select-option value="production_record">生产报工</a-select-option>
            <a-select-option value="stock_in_record">入库记录</a-select-option>
            <a-select-option value="stock_out_record">出库记录</a-select-option>
            <!-- <a-select-option value="stock_check">盘点记录</a-select-option>
            <a-select-option value="stock_transfer">调拨记录</a-select-option> -->
            <a-select-option value="payment">付款记录</a-select-option>
            <a-select-option value="receipt">收款记录</a-select-option>
            <a-select-option value="account_transfer">账户转账</a-select-option>
            <a-select-option value="charge">日常收支</a-select-option>
            <a-select-option value="inspection">质检管理</a-select-option>
            <a-select-option value="role">角色管理</a-select-option>
            <a-select-option value="user">员工账号</a-select-option>
            <a-select-option value="config">系统配置</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.action" placeholder="操作类型" allowClear style="width: 100%" @change="search">
            <a-select-option value="create">新增</a-select-option>
            <a-select-option value="update">修改</a-select-option>
            <a-select-option value="delete">删除</a-select-option>
            <a-select-option value="import">导入</a-select-option>
            <a-select-option value="void">作废</a-select-option>
            <a-select-option value="commit">确认</a-select-option>
            <a-select-option value="submit">提交审批</a-select-option>
            <a-select-option value="approve">通过</a-select-option>
            <a-select-option value="reject">拒绝</a-select-option>
            <a-select-option value="cancel">取消</a-select-option>
            <a-select-option value="production_publish">生产发布工单</a-select-option>
            <a-select-option value="production_close">生产关闭工单</a-select-option>
            <a-select-option value="production_stock_in">生产成品入库</a-select-option>
            <a-select-option value="production_stock_out">生产领料</a-select-option>
            <a-select-option value="production_batch_stock_out">生产批量领料</a-select-option>
            <a-select-option value="production_stock_return">生产退料</a-select-option>
            <a-select-option value="production_batch_stock_return">生产批量退料</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.status" placeholder="操作状态" allowClear style="width: 100%" @change="search">
            <a-select-option value="success">成功</a-select-option>
            <a-select-option value="failure">失败</a-select-option>
            <a-select-option value="exception">异常</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { userOption } from "@/api/option";
import { operationLogList } from "@/api/system";

export default {
  components: {},
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "系统模块",
          dataIndex: "module_display",
        },
        {
          title: "操作类型",
          dataIndex: "action_display",
        },
        {
          title: "操作人员",
          dataIndex: "creator",
          customRender: (_, item) => item.creator_item.name,
        },
        {
          title: "主机",
          dataIndex: "remote_ip",
        },
        {
          title: "操作状态",
          dataIndex: "status_display",
        },
        {
          title: "操作日期",
          dataIndex: "create_time",
        },
        {
          title: "消耗时间",
          dataIndex: "request_duration",
          customRender: (value) => `${value * 1000} 毫秒`,
        },
      ],
      searchForm: { search: "", page: 1, page_size: 10 },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      items: [],
      userItems: [],
    };
  },
  computed: {},
  methods: {
    initData() {
      userOption({ page_size: 99999 }).then((resp) => {
        this.userItems = resp.results;
      });

      this.list();
    },
    list() {
      this.loading = true;
      operationLogList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0];
      let endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
