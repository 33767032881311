import request from "@/utils/request";

// 生产工单
export function productionOrderList(params) {
  return request({ url: `/production_orders/`, method: "get", params });
}

export function productionOrderDetail(params) {
  return request({ url: `/production_orders/${params.id}/`, method: "get", params });
}

export function productionOrderCreate(data) {
  return request({ url: `/production_orders/`, method: "post", data });
}

export function productionOrderUpdate(data) {
  return request({ url: `/production_orders/${data.id}/`, method: "put", data });
}

export function productionOrderDelete(data) {
  return request({ url: `/production_orders/${data.id}/`, method: "delete", data });
}

export function productionOrderNumber(params) {
  return request({ url: `/production_orders/number/`, method: "get", params });
}

export function productionOrderPublish(data) {
  return request({ url: `/production_orders/${data.id}/publish/`, method: "post", data });
}

export function productionOrderClose(data) {
  return request({ url: `/production_orders/${data.id}/close/`, method: "post", data });
}

export function productionOrderMoveUp(data) {
  return request({ url: `/production_orders/${data.id}/move_up/`, method: "post", data });
}

export function productionOrderMoveDown(data) {
  return request({ url: `/production_orders/${data.id}/move_down/`, method: "post", data });
}

export function productionOrderStockOut(data) {
  return request({ url: `/production_orders/${data.id}/stock_out/`, method: "post", data });
}

export function productionOrderBatchStockOut(id, data) {
  return request({ url: `/production_orders/${id}/batch_stock_out/`, method: "post", data });
}

export function productionOrderStockIn(data) {
  return request({ url: `/production_orders/${data.id}/stock_in/`, method: "post", data });
}

export function productionOrderStockReturn(data) {
  return request({ url: `/production_orders/${data.id}/stock_return/`, method: "post", data });
}

export function productionOrderBatchStockReturn(id, data) {
  return request({ url: `/production_orders/${id}/batch_stock_return/`, method: "post", data });
}

export function productionOrderBOMList(params) {
  return request({ url: `/production_orders/${params.id}/bom_list/`, method: "get", params });
}

export function productionOrderWarningList(params) {
  return request({ url: `/production_orders/warning_list/`, method: "get", params });
}

export function productionOrderInternal(data) {
  return request({ url: `/production_orders/${data.id}/internal/`, method: "post", data });
}

export function productionOrderOutsourcing(data) {
  return request({ url: `/production_orders/${data.id}/outsourcing/`, method: "post", data });
}

export function productionOrderProductionRoute(params) {
  return request({ url: `/production_orders/${params.id}/production_route/`, method: "get", params });
}

export function productionOrderProductionTask(params) {
  return request({ url: `/production_orders/${params.id}/production_tasks/`, method: "get", params });
}

export function productionOrderSubProductionOrders(params) {
  return request({ url: `/production_orders/${params.id}/sub_production_orders/`, method: "get", params });
}

export function productionOrderScheduling(data) {
  return request({ url: `/production_orders/${data.id}/scheduling/`, method: "post", data });
}

export function productionOrderImportProject(data) {
  return request({ url: `/production_orders/import_project/`, method: "post", data });
}

// 生产任务
export function productionTaskList(params) {
  return request({ url: `/production_tasks/`, method: "get", params });
}

// 生产记录
export function productionRecordList(params) {
  return request({ url: `/production_records/`, method: "get", params });
}

export function productionRecordDetail(params) {
  return request({ url: `/production_records/${params.id}/`, method: "get", params });
}

export function productionRecordCreate(data) {
  return request({ url: `/production_records/`, method: "post", data });
}

// 车间
export function workshopList(params) {
  return request({ url: `/workshops/`, method: "get", params });
}

export function workshopNumber(params) {
  return request({ url: `/workshops/number/`, method: "get", params });
}

export function workshopCreate(data) {
  return request({ url: `/workshops/`, method: "post", data });
}

export function workshopUpdate(data) {
  return request({ url: `/workshops/${data.id}/`, method: "put", data });
}

export function workshopDelete(data) {
  return request({ url: `/workshops/${data.id}/`, method: "delete", data });
}

// 工作站
export function workstationList(params) {
  return request({ url: `/workstations/`, method: "get", params });
}

export function workstationNumber(params) {
  return request({ url: `/workstations/number/`, method: "get", params });
}

export function workstationCreate(data) {
  return request({ url: `/workstations/`, method: "post", data });
}

export function workstationUpdate(data) {
  return request({ url: `/workstations/${data.id}/`, method: "put", data });
}

export function workstationDelete(data) {
  return request({ url: `/workstations/${data.id}/`, method: "delete", data });
}

// 工序
export function processList(params) {
  return request({ url: `/processes/`, method: "get", params });
}

export function processNumber(params) {
  return request({ url: `/processes/number/`, method: "get", params });
}

export function processCreate(data) {
  return request({ url: `/processes/`, method: "post", data });
}

export function processUpdate(data) {
  return request({ url: `/processes/${data.id}/`, method: "put", data });
}

export function processDelete(data) {
  return request({ url: `/processes/${data.id}/`, method: "delete", data });
}

// 工艺路线
export function productionRouteList(params) {
  return request({ url: `/production_routes/`, method: "get", params });
}

export function productionRouteNumber(params) {
  return request({ url: `/production_routes/number/`, method: "get", params });
}

export function productionRouteCreate(data) {
  return request({ url: `/production_routes/`, method: "post", data });
}

export function productionRouteUpdate(data) {
  return request({ url: `/production_routes/${data.id}/`, method: "put", data });
}

export function productionRouteDelete(data) {
  return request({ url: `/production_routes/${data.id}/`, method: "delete", data });
}
