<template>
  <div>
    <a-card title="生产任务">
      <a-row :gutter="[12, 8]">
        <a-col :span="24" style="width: 240px">
          <a-input v-model="searchForm.search" placeholder="生产工单号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 84px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :scroll="{ x: 2400 }"
          @change="tableChange"
        >
          <template slot="production_order_number" slot-scope="value, item">
            <a @click="detial(item)">{{ item.production_order_item.number }}</a>
          </template>

          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="detial(item)">详情</a-button>
              <a-button type="primary" @click="openCreateModal(item)">生产报工</a-button>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" @create="create" />
  </div>
</template>

<script>
import { productionTaskList } from "@/api/production";

export default {
  components: {
    FormModal: () => import("./FormModal.vue"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 10 },
      loading: false,
      items: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "生产工单",
          dataIndex: "production_order_number",
          scopedSlots: { customRender: "production_order_number" },
        },
        {
          title: "类型",
          dataIndex: "type",
          customRender: (value, item) => item.production_order_item.type_display,
        },
        {
          title: "工作站",
          dataIndex: "goods_name",
          customRender: (value, item) => item.workstation_item?.name,
        },
        {
          title: "工序",
          dataIndex: "goods_name",
          customRender: (value, item) => item.process_item?.name,
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
          customRender: (value, item) => item.goods_item.name,
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
          customRender: (value, item) => item.goods_item.number,
        },
        {
          title: "物料条码",
          dataIndex: "goods_barcode",
          customRender: (value, item) => item.goods_item.barcode,
        },
        {
          title: "物料规格",
          dataIndex: "goods_spec",
          customRender: (value, item) => item.goods_item.spec,
        },
        {
          title: "排产数量",
          dataIndex: "total_quantity",
          width: 100,
        },
        {
          title: "完成数量",
          dataIndex: "production_quantity",
          width: 100,
        },
        {
          title: "开始时间",
          dataIndex: "start_time",
          width: 180,
        },
        {
          title: "预计完成时间",
          dataIndex: "complete_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          fixed: "right",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
      visible: false,
      targetItem: {},
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      productionTaskList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    detial(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.production_order_item.id } });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    openCreateModal(item) {
      this.targetItem = { ...item };
      this.visible = true;
    },
    create() {
      this.list();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
